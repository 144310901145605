<template>
    <div>
        <div v-if="loadFailed" class="loadFailed">
            <p>Something went wrong when loading your portal. Please report this to a member of staff.</p>
            <p>We are sorry for any inconvenience this has caused.</p>
        </div>
        <Loading v-if="!portalLoaded && !loadFailed" />
        <component v-if="portalLoaded" :is="'Template_'+portalData.template" :portal="portalData" :api="getEndpoint()"
            :preview="isPreview" @logGuestActivity="logGuestActivity" :redirect="portalData.exit_url"></component>
    </div>
</template>
<script>
import Template_defaultIon from '../templates/DefaultIon.vue'
import Template_lwCommunityWifi from '../templates/lwCommunityWifi.vue'
import Loading from '@/components/Loading.vue';
import axios from "axios";
import Api from '../helpers/Api.js';
export default {
    components: {
        Template_defaultIon,
        Template_lwCommunityWifi,
        Loading,
    },
    async mounted() {
        Api.getEndpoint();
        console.log(process.env.VUE_APP_API_URL);
        if (this.$route.query.preview) {
            this.isPreview = true;
        }
        this.getPortalDetails();
    },
    methods: {
        getEndpoint(){
            return Api.getEndpoint();
        },
        getPortalDetails() {
            axios.get(Api.getEndpoint() + '/portal/' + this.$route.params.id + '/public/details').then(res => {
                window.document.title = 'Local WiFi - Captive Login - ' + res.data.name;
                this.portalData = res.data;
                this.logGuestActivity('PORTAL_LOAD');
            }).catch(() => { })
        },
        getIONPayload(action) {
            return {
                portal_id: this.$route.params.id,
                mac_address: this.$route.query.mac,
                action: action,
                meta: {
                    apmac: this.$route.query.apmac
                }
            }
        },
        getMTPayload(action) {
            return {
                portal_id: this.$route.params.id,
                mac_address: decodeURI(this.$route.query.mac),
                action: action,
                meta : {
                    mac : decodeURI(this.$route.query.mac)
                }
            }
        },
        logGuestActivity(action) {
            if (this.isPreview) {
                this.portalLoaded = true;
                return;
            }

            let payload = {};
            if (this.portalData.vendor === 'ION') {
                payload = this.getIONPayload(action)
            }
            if (this.portalData.vendor === 'MT') {
                payload = this.getMTPayload(action)
            }
            axios.post(Api.getEndpoint() + '/public/guest/log', payload).then(() => {
                this.portalLoaded = true;
            }).catch(() => {
                this.loadFailed = true
            })
        }
    },
    data() {
        return {
            isPreview: false,
            loadFailed: false,
            portalLoaded: false,
            portalData: {},
        }
    }
}
</script>
<style>
.loadFailed {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
</style>