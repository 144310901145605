<template>
    <div>
        <Loading />
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import axios from 'axios';
import Api from '../helpers/Api.js'
export default {
    components: {
        Loading
    },
    mounted() {
        document.title = 'Local WiFi - Captive Login - Ion Wizard'
        axios.get(Api.getEndpoint() + '/public/guest/IonWizard?site=' + this.$route.params.site + '&network=' + this.$route.query.network).then((res) => {
            this.$router.push({
                path: '/login/' + res.data.portalId,
                query: this.$route.query
            })
        });
    }
}
</script>