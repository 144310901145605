import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Portal from '../views/Portal.vue'
import Home from '../views/Home.vue'
import IonWizard from '../views/IonWizard.vue'

const routes = [
  {
    path: '/login/:id',
    name: 'Portal',
    component: Portal
  },
  {
    path: '/',
    name: 'Home',
    component: Home 
  },
  {
    path: '/IonWizard/:site',
    name: 'IonWizardPortal',
    component: IonWizard 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
