<template>
	<Loading v-if="isLoading" />
	<div class="page">
		<Terms @closeTerms="showTerms = false" v-if="showTerms"/>
		<component is="style">
			:root{--client-primary:{{ colorPrim }};--client-primary-text-color:{{ colorSec }};}
		</component>
		<div class="login_container">
			<div class="location_name">{{ portal.name }}</div>
			<div class="actions_container">
				<form method="post" :action="'https://' + $route.query.post + '/cgi-bin/login?cmd=authenticate'"
					ref="loginForm">
					<input type="hidden" name="user" :value="$route.query.mac" />
					<input type="hidden" name="password" :value="$route.query.mac" />
					<input type="hidden" name="session_timeout" value="540" />
					<input type="hidden" name="url" :value="_redirect">
				</form>
				<button type="button" class="primary" @click="userLogin()">Connect Now!</button>
				<a class="terms_button" @click="showTerms = true">Terms & Conditions</a>
				<p class="terms_text">By clicking "Connect Now", you confirm you have read and agree to the "Terms &
					Conditions"</p>
			</div>
		</div>
		<img src="@/assets/local-wifi.png" style="width:150px;">
	</div>

	<div class="background_image_container">
		<img :src="require(`@/assets/DefaultIon/${background}`)" class="background_image">
	</div>
</template>
<script>
import axios from 'axios';
import Loading from '@/components/Loading.vue';
import Terms from '@/components/Terms.vue';
export default {
	components: {
		Loading,
		Terms
	},
	props: ['portal', 'api', 'preview', 'redirect'],
	mounted() {
		if(this.redirect != null){
			this._redirect = this.redirect
		}
		if (this.portal.meta != null) {
			if (this.portal.meta.background) {
				this.background = this.portal.meta.background;
			}
			if (this.portal.meta.primaryColor) {
				this.colorPrim = this.portal.meta.primaryColor
			}
			if (this.portal.meta.secondaryColor) {
				this.colorSec = this.portal.meta.secondaryColor
			}
		}
	},
	data() {
		return {
			colorPrim: '#FF4486',
			colorSec: 'white',
			background: 'city_1.jpg',
			_redirect: 'https://local-wifi.com/',
			showTerms: false,
		}
	},
	methods: {
		async userLogin() {
			this.isLoading = true;
			if (this.preview) {
				setTimeout(() => {
					window.location = this._redirect;
					this.isLoading = false;
				}, 1000);
				return;
			}
			let macAddress = this.$route.query.mac;
			await axios.post(this.api + '/public/guest/radius', { mac_address: macAddress }).then(() => { });
			this.$emit('logGuestActivity', 'PORTAL_CONNECT');
			console.log('Radius Auth Request Sent');
			this.$refs.loginForm.submit();
		},
		getImage: (image) => {
			return require('../assets/' + image)
		}
	}
}
</script>
<style>
body {
	background-color: #f7f7f7;
	border: 0px;
	margin: 0px;
}

.page {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.login_container {
	max-width: 90vw;
	border-radius: 10px;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0px 3px 0px 0px rgb(0 0 0 / 5%);
	padding: 2em;
	margin-bottom: 2em;
}

.location_name {
	width: 100%;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	color: #444;
	font-size: 1.5em;
}

.actions_container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 1em;
}

button {
	margin-top: 0.5em;
}

.login-link {
	width: 90%;
}

input[type=submit].primary,
button.primary {
	-moz-transition: background-color .25s ease-in-out;
	-webkit-transition: background-color .25s ease-in-out;
	-ms-transition: background-color .25s ease-in-out;
	transition: background-color .25s ease-in-out;
	-webkit-appearance: none;
	display: inline-block;
	background: var(--client-primary);
	color: var(--client-primary-text-color) !important;
	text-decoration: none !important;
	border-radius: 6px;
	font-weight: 800;
	outline: 0;
	border: 0;
	cursor: pointer;
	font-size: 1.35em;
	padding: 0.6em 1.5em;
	width: 100%;
}

button.gray {
	-moz-transition: background-color .25s ease-in-out;
	-webkit-transition: background-color .25s ease-in-out;
	-ms-transition: background-color .25s ease-in-out;
	transition: background-color .25s ease-in-out;
	-webkit-appearance: none;
	position: relative;
	display: inline-block;
	background: rgba(0, 0, 0, 0.15);
	color: #fff;
	text-decoration: none;
	border-radius: 6px;
	font-weight: 800;
	outline: 0;
	border: 0;
	cursor: pointer;
	font-size: 1.35em;
	padding: 0.6em 1.5em;
	width: 90%;
}

.background_image {
	filter: blur(5px);
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.background_image_container {
	width: 100%;
	height: 100%;
	z-index: -100;
	position: absolute;
	top: 0;
	overflow: hidden;
}

.a_container {
	position: absolute;
	z-index: 10;
	top: 0;
	background-color: rgba(0, 0, 0, 0.50);
	width: 100vw;
	height: 100vh;
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.a_box {
	max-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.a_item {
	width: 90%;
	object-fit: contain;
	height: 90%;
}

.a_count {
	font-size: 20px;
	color: white;
	font-family: 'Poppins', sans-serif;
}

.terms_text {
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
}

.terms_button {
	margin-top: 1em;
	font-family: 'Poppins', sans-serif;
	color: var(--client-primary);
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--client-primary-text-color);
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--client-primary);
}
</style>